import React, {useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const TextContent = ({ content='privacy-policy'}) => {

  let title = 'Text Content'
  let metaDescription = 'Text Content'
  let body = [];
  if(content == 'privacy-policy'){
    title = 'Privacy Policy'
    metaDescription = 'Privacy policy of Epicenter tools and sites'
    body = [
          <h1>Privacy Policy</h1>,
          `Last Modified: March 26, 2024`,
          <h3>Cookies or Browser Cookies</h3>,
          `We use cookies to improve site experience like being able to show you a list of promo codes from your liked creators without loggin in. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies`,
          <h3>Information We Collect</h3>,
          `Users may choose to submit their email to receive promotional material and information from users on the platform. Users may also choose to opt out of messaging at any time. This information is completely optional to use the site and is only used if users explicitly opt in.`
        ]
  }else if(content == 'terms-and-conditions'){
    body = []
  }else if(content == 'mission'){
    title = 'Why our mission is to increase revenue for online businesses'
    metaDescription = `Putting your best side on display to your customers is not always easy. That's where we come in. We offer a suite of tools to show off to your leads in the best way.`
    body = [
      <h1>Why our mission is to increase revenue for online businesses</h1>,
      <h3>Does this strike too close to home?</h3>,
      <p>You've decided to finally get serious about your social media page for your small business.<br/>
      For weeks, you stay consistent.<br/>
      You post regularly.<br/>
      You're even coming up with new ideas every week that have never been done before - and mixing in some trending audios too.<br/>
      But at the end of the month - you only have a few new followers and no new customers to show for your hard work.</p>,
      <p>I've been there and it sucks. Burnout ensued and I gave up on a hobby I really enjoyed for a while, wondering why I couldn't make something that people wanted.</p>,
      <p>The thing is, it was never a problem with my products - I had a communication issue.
      I was unable to properly explain the value I had to the people I was able to reach. And to the people who were curious, I did not have a proper outlet for them.</p>,
      <p style={{fontWeight:'bold'}}>If this sounds familiar to you or someone you know, then you are precisely who I am trying to reach</p>,
      <p>In the past months, I've been building Epicenter to alleviate a lot of the hardship people face with similar strategies.
      Software can only get you so far though. There is quite a bit of human ingenuity required to compete in today's vast online market.
      That is why I've also been studying content, marketing strategies, and communication in general to build templates and plans for growing small businesses.</p>,
      <p>If you want to build a business that truly creates value instead of trading your time for money at a job you hate - send me an <a href="mailto:justin@epicenters.io" style={{textDecoration:'underline'}}>email</a>.
      Whether you could find benefit from a website, a tailored marketing plan, or more products that provide value to your followers, we'll find how to best assist you in your business' growth.</p>
    ]
  }else if(content == 'info'){
    title = 'About Epicenter'
    metaDescription = `How we grow creator revenue`
    body = [
      <h1>About Epicenter</h1>,
      <p>Epicenter is a platform designed to help people start and grow businesses surrounding content and social media.</p>,
      <p>The creator economy continues to grow, but if you are new to the space, its often confusing to get started.
      Once you have an audience, you shouldn't have to wait around for social media to monetize your content either. There's better ways to create a valuable audience.</p>,
      <p>We've monetized pages with as few as 1,000 followers.
      You have unique value to your audience and Epicenter is designed to help you exemplify that uniqueness.
      By crafting valuable offers and using appropriate revenue streams you can build a business with small but dedicated audiences.</p>,
      <p>Epicenter supplies the tools:</p>,
      <ul><li>Landing Pages</li><li>Email List Builder</li><li>Shop</li><li>Affiliate Extension</li></ul>,
      <p>to open your brand up for unique revenue opportunities, like:</p>,
      <ul><li>Affiliate Marketing</li><li>Sponsorships</li><li>Online Sales</li></ul>,
      <p>With the right tools and an offer designed for YOUR audience, you can start to build the business that works for you instead of the other way around.</p>
    ]
  }else if(content == 'landing'){
    title = 'Epicenter Pages'
    metaDescription = `Landing pages designed to convert`
    body = [
      <h1>Landing Pages</h1>,
      <p>So you've created your social media, posted a few times, now what?</p>,
      <p>Many blogs out there will tell you to get a "link in bio" site. Here you can list all of your socials for people to follow you OR direct them somewhere else like a shop or patreon for them to support you.</p>,
      <p>Truth is, you do need to give your die-hard fans an outlet to get more of your content, but there is a better way than creating a uninterested list of links.</p>,
      <p>Epicenter landing pages are as easy to set up as your typical "link in bio" site, but they make your main offers more appealing while reinforcing your brand.</p>,
      <p>Unique layouts help you emphasize why its worth it to follow you and guide audiences to actions that will actually support you.</p>,
      <p>If you are curious how a fresh landing page could impact your business, <a href='/waitlist'>join here</a>. If you don't make your subscription cost back in 90 days, we give you a full refund.</p>
    ]
  }else if(content == 'winwin'){
    title = 'WinWin'
    metaDescription = `The browser extension for reclaiming your affiliate revenue`
    body = [
      <h1>WinWin</h1>,
      <p>When your audience enjoys your content, they will go out of their way to support you. This often manifests as making sure they click affiliate links of yours before buying something that they would have bought anyway.</p>,
      <p>Even so, content creators often make it difficult to find their deals and promotions. They get embedded deep into content, where often, you need to know where to look to find to support your creators.</p>,
      <p>WinWin is an extension developed by us that starts to make this process easier for the consumer.</p>,
      <p>They can install the extension to their browser and follow you.
      Then, your discounts show up for them automatically as they are shopping on sites you are affiliated with.
      No need for them to expend the effort of scrubbing through videos to find a specific ad read or find a specific link in a long list of affiliates that you've put in your description</p>,
      <p>We should make it easy for our audiences to support us!</p>,
      <p>Get the <a href='https://chromewebstore.google.com/detail/WinWin%20-%20Find%20Discounts%20AND%20Support%20Creators/mmkgjphdfgikimibjkcldhollngjbjlj'>WinWin Extension</a> to more easily support your favorite creators.</p>,
      <p>If you are an affiliate that would like to list on WinWin, sign up to our <a href='/waitlist'>Waitlist</a>. We're running special promotions for creators to get on the platform as we've just launched.</p>
    ]
  }

  return (
    <>
      <Helmet >
        <title>{title}</title>
        <meta name='description' content={metaDescription}/>

      </Helmet>
      <div className="TextContent" style={{display:'flex'}}>

        <div style={{margin:"auto", textAlign:'left', width:'80%',fontSize:'1.15em'}}>
          {body}
        </div>

      </div>
    </>
  )
};

export default TextContent;
