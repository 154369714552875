import React, {useEffect, useState } from 'react';
import * as Assets from './'
import './AssetGrid.css'

import VideoAsset from './VideoAsset'

const AssetGrid = ({}) => {

  return <div className='AssetGrid'>
    <Assets.InstagramComment preview='true'/>
    <VideoAsset />
    <VideoAsset />
  </div>
}
export default AssetGrid;
