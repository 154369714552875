import React, {useEffect, useState } from 'react';
import VideoAsset from '../VideoAsset'
import './InstagramComment.css'

const InstagramComment = ({preview=false}) => {

  // edit elements
  // export functionality

  return <VideoAsset preview={preview}>
    <div className='InstagramComment'>
      <div class='comment'>
        <img class='profile' src='https://i.pinimg.com/736x/16/4a/7d/164a7dff8a25518391228bf9852a7e14.jpg'/>
        <div class='text'>
          <span class='username'>barry_hadder</span>
          <img src='https://img.icons8.com/ios_filled/512/228BE6/instagram-verification-badge.png' class='verified'/>
          <span>Your a newbie! You dont know how to make a blender plugin!</span>
        </div>
        <img src='https://static.vecteezy.com/system/resources/previews/015/309/631/non_2x/heart-icon-on-transparent-background-free-png.png' class='like'/>
        <div class='timestamp'>
          <span>2h</span><span>4 likes</span><span>Reply</span>
        </div>
      </div>
    </div>
  </VideoAsset>
}
export default InstagramComment;
