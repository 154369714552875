import styles from './Waitlist.css';

import Input from '../forms/Input';
import Toggle from '../forms/Toggle';
import Button from '../forms/Button';

import {valid_email} from '../utils/regularExpressions'

import React, {useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';

import { Helmet } from 'react-helmet-async';


const Waitlist = ({}) => {

  const addToastMessage = useOutletContext();


  useEffect(() => {

    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }


  }, []);


  const sendWaitlistSubmission = ()=>{
    window.emailjs.init({publicKey: "GtdfAhb79CyoEPGVI"});

    let email = document.querySelector('input[name="email"]').value;
    console.log(document.querySelector('input[name="winwin"]').value);

    if(valid_email.test(email)){
      // these IDs from the previous steps
      window.emailjs.sendForm('service_mj99f3j', 'template_xivu9wm', '#waitlistForm')
          .then(() => {
              addToastMessage({type:'Success', message:'Thanks for your submission', detail:'You should hear back within 24hrs'})
          }, (error) => {
              addToastMessage({type:'Error', message:'Error', detail:'Info not submitted - email justin@epicenters.io to join waitlist'})
          });
    }else{
      addToastMessage({type:'Error', message:'Error', detail:'Please Enter A Valid Email'})
    }

  }

  return (
    <>
      <Helmet>
        <title>Waitlist</title>
        <meta
          name="description"
          content="Sign up for Epicenter, WinWin, and other projects here"
        />
      </Helmet>

      <div className='Waitlist'>
        <form id='waitlistForm' style={{margin:'auto'}}>
          <h1>Waitlist</h1>
          <div>Send us a request to get access to Epicenter or WinWin</div><br/>
          <div>We process all requests individually. Current response time: &lt;24hrs </div>
          <Input name='fullName' label='Full Name*' />
          <Input name='email' label='Email*' />
          <Input name='socialMediaHandle' label='Social Media Handle' />
          <Input name='referral' label='Referal Code (not required)' />
          <Toggle name='winwin' label='Want to list on WinWin?' />
          <Button label='Submit' onClick={(event)=>{event.preventDefault();sendWaitlistSubmission();}} />
        </form>
      </div>

    </>
  )
};

export default Waitlist;
