import React, {useEffect, useState } from 'react';
import { Button } from '../forms'
import './VideoAsset.css'

const Preview = ({ children }) => {

  return <div className='Preview'>
    {children}
  </div>
}

const Render = ({ children }) => {

  return <div className='Render'>
    {children}
    <Button />
  </div>
}

const VideoAsset = ({ children, preview, params=[], updateParams=()=>{} }) => {

  // edit elements
  // export functionality

  return <div className='VideoAsset'>

    {preview &&
      <Preview >
        {children}
      </Preview>
    }

    {!preview &&
      <Render >
        {children}
      </Render>
    }

  </div>
}
export default VideoAsset;
