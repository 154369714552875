import React from 'react';
import styles from './layout3.css';
import '../utils/animations.css';
import Card from '../cards/Card';
import Button from '../forms/Button';
import ContactIcon from '../components/ContactIcon';
import SocialButton from '../components/SocialButton';

import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import { downloadContactCard } from '../utils/fileProcessing'


const Layout3 = ({ profile, links, theme, linkClick, subscribe }) => {


  const socialButtons = links.map((card, index) => {
      if(links[index]['section'] == 1){
        return <SocialButton href={card['url']} link_id={card['id']} type={theme['socials_style']} fill={theme['tertiary']} onClick={linkClick}/>
      }
      return null;
    })

  let primary_set = false;
  const cards = links.map((card, index) => {
      if(card['section'] == 2){
        let first = !primary_set;
        primary_set = true;
        return <Card key={index} link_id={card['id']} theme={theme} img={card.image?.url} type={first && theme['cta']=='default_cta' ? 'Primary' : ''} className='MoveCard' onClick={linkClick} {...card} />;
      }
      return null;
    })
  if(theme['cta'] == 'subscribe_cta'){
    cards.unshift(<Card key='-1' type='Primary' onClick={subscribe} title="Subscribe" text={profile['subscribe_copy']}/>)
  }

  let profileImgSrc = 'https://via.placeholder.com/150';
  if('image' in profile){
    profileImgSrc = profile['image']['url'];
  }

  let fullArt = theme['card_style'] == 'fullArt';
  let backgroundFullArt = {};
  let imgStyle = {};
  let descriptionStyle = {};
  let frameStyle = {};
  if(fullArt){
    backgroundFullArt = {background:`url(${profileImgSrc})`, backgroundSize:'cover', backgroundPosition:"center"}
    imgStyle = {display:'none'}
    descriptionStyle = {display:'none'};
    frameStyle = {background:'none'}
  }

  return (
    <div className={"Layout3 shimmer " + 'fullArt'.repeat(fullArt)} style={{}}>


      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" style={{display:"none"}}>
        <defs>
          <path id="clipLeft" d="M0,0 v 20 h 20 a 10,10 0 0 1 10,-10 a 10,10 0 0 0 10,-10 z" />
        </defs>
        <path fill="blue" clip-path="url(#clipLeft)" d="M 0 0 v 100 h 100 v -100 z"/>
      </svg>


      <div class='cardContent' style={backgroundFullArt}>
        <div class="cardTitle">
          <span class="basic clipLeft">BASIC</span>
          <span class="name">
            {profile['display_name']}
            { theme['collect_email']?
            <span style={{ fontSize:'.5em',cursor:'pointer'}} onClick={subscribe}>
                +
            </span>
            :<></>}
          </span>
          <span class="hp" style={{marginRight:".5em"}}><span style={{fontSize:'.7em'}}>hp</span><span style={{fontSize:'1em'}}>120</span><img></img></span>
        </div>

        <div class="imageContainer" style={frameStyle}>
          <img class="profileImg" style={imgStyle} src={profileImgSrc}/>

          <div style={{display:'none', position:'absolute', top:'0', right:'0'}} class="social-section">
            {socialButtons}
          </div>
        </div>

        <span class="description" style={descriptionStyle}>{profile['bio']}</span>

        <div class="link-section VerticalList" style={{margin:"1em 0 0 0"}}>
          {cards}
        </div>

        <div class="cardFooter">
          <div class="footerBorder"></div>
            <div style={{height:"2em", width:"60%", marginLeft:"1em", float:'left'}} class="HorizontalList start social-section">
              {socialButtons}
            </div>


            { theme['download_contact']?
              <div class="Contact" onClick={()=>downloadContactCard(profile.id, profile.display_name)} >
                Save Contact <u style={{fontWeight:'bold', fontSize:"1.2rem"}}>↓</u>
              </div>
              :<></>
            }
          <div class="footerBorder" style={{marginBottom:".4em"}}></div>
        </div>


      </div>

    </div>
  );
};

export default Layout3;
