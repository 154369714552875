import React from 'react';
import styles from './Directory.css'

import Cookies from 'js-cookie';
import {useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Helmet } from 'react-helmet-async';

import AffiliateLink from './AffiliateLink'
import Input from '../forms/Input'


const filterLinks = (links, query) => {
  let newLinks = [];
  links.forEach((link, i) =>{
    if(link.title.toLowerCase().includes(query.toLowerCase()) ||
      link.url.toLowerCase().includes(query.toLowerCase()) ||
      link.display_name.toLowerCase().includes(query.toLowerCase())){
      newLinks.push(link)
    }
  })
  return newLinks
}

const Directory = ({  }) => {


  // function linkClick(event) {
  //   const link = event.target.closest("a").dataset.link;

  //   fetch(process.env.REACT_APP_API_URL + '/events', {
  //     method: 'POST',
  //     headers: {
  //       'membership': Cookies.get('membership'),
  //       'Authorization': Cookies.get('Authorization'),
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ 'link_id':link, 'member_id':Cookies.get('membership'), 'profile_id':profile['id'], 'variety':'outbound', 'campaign':campaign, 'referrer_url':referrer, 'device':deviceType })
  //   })
  // }
  const [links, setLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  let filteredLinks = filterLinks(links,searchQuery);

  const fetchDataRails = () => {

    fetch(process.env.REACT_APP_API_URL + '/show_promos/' + Cookies.get('membership'), {
      headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setLinks(data);
      });
  }

  useEffect(() => {
    fetchDataRails();
  },[]);

  let profile_groups = {}

  filteredLinks.forEach((item, i) => {
    if(!(item.display_name in profile_groups)){
      profile_groups[item.display_name] = [];
    }
    profile_groups[item.display_name].push(item)
  });



  return (
    <>
      <Helmet >
        <title>Affiliate Directory - Find your creator's discount codes</title>
        <meta name='description' content="Find all of your favorite creator's affiliate codes in one place so you can support them when shopping online" />
      </Helmet>
      <div className="Directory">
        <h1>Welcome to <span style={{color:'var(--quarnary)',fontWeight:'bold'}}>Affiliate Directory</span></h1>
        <p>Get discounts on websites you visit every day all while supporting your favorite creators.</p>
        <p>If you "Subscribe" to a creator's Epicenters page, their promotional links will show up here next time you visit this page.</p>
        <p>Install our <a href="https://chromewebstore.google.com/detail/mmkgjphdfgikimibjkcldhollngjbjlj">Chrome Browser Extension</a>!</p>
        {links.length == 0 ? <p>No discounts yet? Check out some creators: <a href='/justin'>Justin</a> </p> : ''}


        <Input label='Search Links' value={searchQuery} onChange={(ev)=>{setSearchQuery(ev.target.value)}}/>
        {Object.keys(profile_groups).map((key, index)=>{
          return (
            <>
              <h2>{key}'s Discounts</h2>
              <div></div>
              <div class='Links HorizontalList Scroll' >
              {profile_groups[key].map((link, i)=>{
                return <AffiliateLink url={link['url']} code={link['promo']} title={link['title']} text={link['text']} />
              })}
              </div>
            </>
          )
        })}
      </div>
    </>
  );
};

export default Directory;
