import Input from '../forms/Input';
import Button from '../forms/Button';
import Toggle from '../forms/Toggle';
import ToastContext from '../App';
import { valid_email } from '../utils/regularExpressions'

import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams, useOutletContext } from "react-router";


function subscribe(event, profile_id) {
  const first_name = event.target.closest(".Subscribe").querySelector('input[name="first_name"]').value;
  const last_name = event.target.closest(".Subscribe").querySelector('input[name="last_name"]').value;
  const email = event.target.closest(".Subscribe").querySelector('input[name="email"]').value;

  fetch(process.env.REACT_APP_API_URL + '/subscribe', {
    method: 'POST',
    headers: {
      'membership': Cookies.get('membership'),
      'Authorization': Cookies.get('Authorization'),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'profile_id':profile_id, 'first_name':first_name, 'last_name':last_name, 'email':email})
  })

}

const Subscribe = ({profile_id, subscribeText, buttonText, name}) => {

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [email, setEmail] = useState('');

  const addToastMessage = useOutletContext();

  return (
    <div className="Subscribe" style={{background:"linear-gradient(-45deg, #141417ff, #4a4f59ff)", padding:"2em", borderRadius:'1em', color:'white', zIndex:'2'}}>
      {subscribeText ?? "Sign up to my email list for exclusive benefits"}
      <Input name="first_name" label="First Name"/>
      <Input name="last_name" label="Last Name"/>
      <Input name="email" label="Email" value={email} onChange={(ev)=>setEmail(ev.target.value)}/>
      <span style={{display:'flex',flexDirection:'row',alignItems:'center', textAlign:'left', gap:'1em'}}>
        <Toggle value={submitEnabled} onChange={()=>setSubmitEnabled(prevState => !prevState)}/>
        <span style={{maxWidth:'20em'}}>I agree to share my email and receive communication from {name ? name : 'this site'}</span>
      </span>
      <Button disabled={(!submitEnabled || !valid_email.test(email))} label={buttonText ?? "Enroll"} onClick={(event)=>{subscribe(event, profile_id);addToastMessage({type:'Success', message:'Success', detail:'Email has been submitted - you will now receive exclusive offers from the creator'});}}/>
    </div>
  )
};

export default Subscribe;
