import React from 'react';
import styles from './AffiliateLink.css'

const AffiliateLink = ({ url, code, title, text, img }) => {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

return <a className='AffiliateLink' href={url} target='_blank'>
          <div class='AffiliateImg'></div>
          <div class='AffiliateTitle'>{title} ↗</div>
          <div class='AffiliateCode' onClick={(ev)=>{ev.preventDefault();let popup = ev.target.closest('.AffiliateCode').querySelector('.CopiedConfirmation');copyToClipboard(code);popup.classList.add('Visible');setTimeout(() => {popup.classList.remove('Visible');}, 2000);}}>
            Code: <span style={{color:'var(--quarnary)', fontWeight:'bold'}}>{code}</span>
            <svg class="" viewBox="-1.5 -1.5 103 103" preserveAspectRatio="xMidYMid meet" style={{height:'1.2em',fill:'var(--tertiary)', marginLeft:'.25em'}}>
              <use href='clipboard.svg#layer1' />
            </svg>
            <div class='CopiedConfirmation'>Copied ✔</div>
          </div>
          <div class='AffiliateDescription'>{text || url.split('//')[1]}</div>
        </a>;
}

export default AffiliateLink;
