import styles from './Header.css';

import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const Header = ({sticky=false}) => {

  let home = <Link to="/"><img src="/logo_full.png"/></Link>;

  let links = [
    {
      text: "Products", links:[
        {text:"Landing Pages", href:"/landing"},
        {text:"WinWin", href:"/winwin"},
        {text:"Pricing", href:"/pricing"}
      ]
    },
    {
      text:"About", links:[
        {text:"Info",href:"/info"},
        {text:"Mission",href:"/mission"}
      ]
    },
    {text:"QR Codes", href:"/qr-maker"}
  ]

  let cta = <a class="CTA" href="/login">Login</a>;

  const [openDrawer, setOpenDrawer] = useState(null);
  const [mobileDrawer, openMobileDrawer] = useState(false);


  return (
    <div className="Header">

      <div class="HeaderStart">
        {home}
      </div>

      <div class="DesktopMenu">
        {links.map((link, idx) =>{
          if(link.href){
            return(<div class="Link">
              <Link to={link.href}>{link.text}</Link>
            </div>)
          }else{
            return(<div class="NestedMenu">
              <div class="Link">
                {link.text}
              </div>
              <div class='Drawer' >
                {link.links.map((item, jdx)=>{
                  return(<div class="Link">
                    <Link to={item.href}>{item.text}</Link>
                  </div>)
                })}
              </div>
            </div>)
          }

        })}
      </div>

      <div class="HeaderEnd">
        <div class="Hamburger" onClick={() => openMobileDrawer(prevDrawer => !prevDrawer)}>
          <img src={(mobileDrawer ? 'X.png' : 'hamburger.png')}/>
        </div>
        {cta}
      </div>



      <div class={"MobileMenu " + (mobileDrawer ? 'open' : '')}>

        {links.map((link, idx) =>{
          if(link.href){
            return(<div class="Link" onClick={()=>{openMobileDrawer(false)}}>
              <Link to={link.href}>{link.text}</Link>
            </div>)
          }else{
            return(<>
              <div class="Link" onClick={()=>{setOpenDrawer(openDrawer == idx ? null : idx)}}>
                {link.text}
              </div>
              <div class={openDrawer==idx ? 'Drawer Open' : 'Drawer'} >
                {link.links.map((item, jdx)=>{
                  return(<div class="Link" onClick={()=>{openMobileDrawer(false)}}>
                    <Link to={item.href}>{item.text}</Link>
                  </div>)
                })}
              </div>
            </>)
          }
        })}

        {cta}
      </div>


    </div>
  )
};

export default Header;
