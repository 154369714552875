import React from 'react';
import styles from './layout4.css'

import ContactIcon from '../components/ContactIcon';
import SocialButton from '../components/SocialButton';
import Button from '../forms/Button';
import SingleLineText from '../components/SingleLineText';
import Loader from '../components/Loader';

import Spline from '@splinetool/react-spline';
import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import { downloadContactCard } from '../utils/fileProcessing'

import Card from '../cards/Card';



const Layout1 = ({ profile, links, theme, linkClick, subscribe }) => {

  let socialButtons = links.map((card, index) => {
      if(links[index]['section'] == 1){
        return <SocialButton href={card['url']} link_id={card['id']} type={theme['socials_style']} fill={theme['tertiary']} onClick={linkClick}/>
      }
      return null;
    })
  socialButtons = socialButtons.filter(item => item !== null);

  let primary_set = false;
  let cards = links.map((card, index) => {
      if(card['section'] == 2){
        let first = !primary_set;
        primary_set = true;
        return <Card key={index} link_id={card['id']} className={theme['card_type']} theme={theme} img={card.image?.url} type={first && (theme['cta']=='default_cta' || !theme['cta'] ) ? 'Primary' : ''} onClick={linkClick} {...card} />;;
      }

      return null;
    })
  cards = cards.filter(item => item !== null);
  if(theme['cta'] == 'subscribe_cta'){
    cards.unshift(<Card key='-1' type='Primary' className={theme['card_type']} onClick={subscribe} title="Subscribe" text={profile['subscribe_copy']}/>)
  }

  let profileImgSrc = 'https://via.placeholder.com/150';
  if('image' in profile){
    profileImgSrc = profile['image']['url'];
  }


  let splineId = theme['spline_project']; //'fdgAVUipBTuHWX2w'; //ATRzpG6zzuU-t08c
  let imageFallback = theme['image_fallback']?.['url'];

  const [loaded, setLoaded] = useState(false);
  let threeDContent
  let loader = <Loader display={!loaded}/>;

  // if img_override is set, set that to the loader content and the threeDContent
  // if spline_project is set, we want to use it
  if(imageFallback){
    loader = <img src={imageFallback} style={{position:'absolute', top:'0', right:'0vw', objectFit: 'contain', width:'100%', height:'100%', display:loaded?'none':'block'}}/>;
  }
  if(splineId){
    threeDContent = <Spline scene={`https://prod.spline.design/${splineId}/scene.splinecode`} onLoad={() => {setTimeout(()=>setLoaded(true), 250)}}/>;
  }


  return (
    <div className="Layout4" style={{}}>

      <div class='ClipContent'>
        <div class='MainContent'>
         {threeDContent}
         {loader}
        </div>
      </div>

      <div class='info'>
        <div class="name"><SingleLineText text={profile['display_name']}/></div>
        <div class="bio">{profile['bio']}</div>
      </div>

      <div class='None'>

      </div>

      <div style={{width:'80%', margin:'auto', display:'grid', alignItems:'center', justifyItems:'center'}}>
        {cards[0]}
      </div>

      <div className="social-section">
        {socialButtons}
      </div>

      <div className="HorizontalList Scroll" >
        <div className="link-section">
        {cards.slice(1)}
        </div>
      </div>

      { theme['collect_email']?
      <>
        <div style={{position:'absolute', top:'0', right:'0', background:'none', border:'none', color:'var(--tertiary)', fontWeight:'bolder', cursor:'pointer'}}>
          <Button label="Subscribe" color={theme['tertiary']} noPadding={true} onClick={subscribe}/>
        </div>
      </>
      :<></>}

    </div>
  );
};

export default Layout1;
