import styles from './AddCodesFree.css';

import Input from '../forms/Input';
import Toggle from '../forms/Toggle';
import Button from '../forms/Button';

import {valid_email} from '../utils/regularExpressions'

import React, {useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';

import { Helmet } from 'react-helmet-async';


const AddCodesFree = ({}) => {

  const addToastMessage = useOutletContext();

  const [email, setEmail] = useState('');
  const [handle, setHandle] = useState('');
  const [link, setLink] = useState('');
  const [code, setCode] = useState('');
  const [shop, setShop] = useState('');
  const [description, setDescription] = useState('');


  const submitWinWinCode = (ev, email, handle, link, code, shop, description)=>{
    ev.preventDefault();
    if(valid_email.test(email) && link && (code || handle)){

      fetch(process.env.REACT_APP_API_URL + '/register_winwin_code', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        // body: JSON.stringify(params),
        body: JSON.stringify({email:email,handle:handle,link:link,code:code,shop:shop,description:description})
      })
        .then(response => {
          if(response.ok){
            addToastMessage({type:'Success', message:'Success', detail:'Code Submitted For Review'});
          }else{
            response.json().then(data=>addToastMessage({type:'Error', message:'Error', detail:data.error}))

          }
        })
        .catch(error => {
          addToastMessage({type:'Error', message:'Error', detail:'Unknown Error Occurred'});
        });


    }else{
      addToastMessage({type:'Error', message:'Error', detail:'Please Fill Full Form Including A Valid Email'})
    }

  }

  return (
    <>
      <Helmet>
        <title>Add Affiliate Codes</title>
        <meta
          name="description"
          content="Add codes for your audience to use for free"
        />
      </Helmet>

      <div className='AddCodesFree'>
        <form id='addCodes' style={{margin:'auto'}}>
          <h1>Add Affiliate Codes <span style={{color:'var(--quarnary)'}}>|</span> Registration fee:<span style={{textDecoration:'line-through', color:'red'}}> $25 </span>Waived</h1>
          <div>Add an affiliate link or code to WinWin for your audience to access.</div>
          <div>For a limited time, we're waiving the one time registration fee to verify creators on the platform.</div><br/>
          <div>All codes uploaded will be manually approved for subscriber safety.
          We will send a confirmation email once reviewed with instructions on how to give your subscribers access.</div>
          <Input name='email' label='Email*' value={email} onChange={ev=>setEmail(ev.target.value)}/>
          <Input name='socialMediaHandle' label='Social Media Handle or Link*' value={handle} onChange={ev=>setHandle(ev.target.value)}/>
          <Input name='link' label='Affiliate Link*' value={link} onChange={ev=>setLink(ev.target.value)}/>
          <Input name='code' label='Discount Code' value={code} onChange={ev=>setCode(ev.target.value)}/>
          <Input name='shop' label='Affiliate Company Name' value={shop} onChange={ev=>setShop(ev.target.value)}/>
          <Input name='text' label='Description of Promotion' value={setDescription} onChange={ev=>setDescription(ev.target.value)}/>
          <Button label='Submit' onClick={(event)=>{submitWinWinCode(event, email, handle, link, code, shop, description)}} />

          <br/><div>Your subscribers will be eligible for this promotion when browsing the associated shop.
          Codes may be rejected for incomplete information.</div><br/>
          <div>Affiliate promotions offering a discount are preferred but not required for WinWin.</div><br/>
          <div>Want to update codes instantaneously and access Epicenter's other features? <a href='/waitlist'>Sign Up Here</a>.</div>
        </form>
      </div>

    </>
  )
};

export default AddCodesFree;
