import styles from './Login.css';
import MultiSelect from '../forms/MultiSelect';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Toggle from '../forms/Toggle';
import ToastContext from '../App';
import React, {useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useParams, useOutletContext } from "react-router";


const Login = () => {
  let { table, id } = useParams();
  const addToastMessage = useOutletContext();

  let homeDir = '';

  const [tableDefinition, setTableDefinition] = useState({"email":{"editable":true,"type":"string"},"password":{"editable":true,"type":"string"}});


  // Cookies.set('Authorization', '2', { expires: 7 });
  // const cookieValue = Cookies.get('myCookie');
  // Cookies.remove('myCookie');
  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setTableDefinition(prevState => ({ ...prevState, [name]: {...prevState[name], 'value':value}}));
  }

  // for each field in our table, we need to check if it is editable and which type of input we will use
  // tableDefinition['email'] = {"editable":true,"type":"string"};
  // tableDefinition['password'] = {"editable":true,"type":"string"};

  const inputs = Object.keys(tableDefinition).map((key, index) => {
    let editable = tableDefinition[key]['editable'];
    let type = tableDefinition[key]['type'];

    if(editable){
      if(type == 'bool'){
        return <Toggle name={key} label={key}/>;
      }else if (type == 'string') {
        return <Input name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange} isPassword={key=='password' ? true:false}/>;
      }else if (type == 'selection') {
        return <MultiSelect label={'color'} options={['blue','red']}/>;
      }
    }
    return null;
  })
  // onChange={(e) => setPassword(e.target.value)}

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(process.env.REACT_APP_API_URL + '/login', {
      headers: {
        'Content-Type': 'application/json'
      },
      method:'POST',
      body: JSON.stringify({ email: tableDefinition['email']['value'], password: tableDefinition['password']['value'] })
      // body: new URLSearchParams({ email: email, password: password }).toString()
    })
      .then((response) => response.json())
      .then((data) => {
        if('auth_token' in data){
          addToastMessage({type:'Success', message:'Success', detail:'Logged In: Redirecting'})
          Cookies.set('Authorization', data['auth_token']);
          var link = document.createElement('a');
          link.href = `${homeDir}/admin/pages/`;
          document.body.appendChild(link);
          link.click();
          // <a href={`${homeDir}/admin/profiles/${profile['id']}`}  >Profile</a>
        }else{
          addToastMessage({type:'Error', message:'Error', detail:'Login details are incorrect'})
        }
      });
  };

  return (
    <div className="Login">
      <form onSubmit={handleSubmit} style={{margin:"auto"}}>
        <h1>Login</h1>
        <div>No Account?</div>
        <a href='/waitlist'>Register</a>
        {inputs}
        <a href="/password_reset" style={{color:'white',textAlign:'left'}}>Forgot Password?</a>

        <Button type="submit" label="Submit"/>

      </form>
    </div>
  );
};

export default Login;
