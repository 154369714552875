import logo from './logo.svg';
import './App.css';
import Layout from './layouts/LayoutWrapper';

import Product from './store/Product';

import Template from './marketing/Template';
import Hero from './marketing/Hero';
import Hero2 from './marketing/Hero2';
import Waitlist from './marketing/Waitlist';
import AddCodesFree from './marketing/AddCodesFree';
import TextContent from './marketing/TextContent';
import Pricing from './marketing/Pricing';
import Blog from './marketing/Blog';
import BlogArticle from './marketing/BlogArticle';
import QRGenerator from './components/QRGenerator';
import * as VideoAssets from "./videoAssets";

import ToastMessageDisplay from './components/ToastMessageDisplay'
import Parallax from './components/Parallax';
import Directory from './affiliateDirectory/Directory';
import ManagementHeader from './admin/ManagementHeader';
import CRUD from './admin/CRUD';
import ListView from './admin/ListView';
import Analytics from './admin/Analytics';
import PageEditor from './admin/PageEditor';
import Login from './admin/Login';
import PasswordReset from './admin/PasswordReset';
import LinkRedirect from './components/LinkRedirect';

import FrankenCard from './frankenstein/FrankenCard'
import FrankenSocial from './frankenstein/FrankenSocial'
import SaleComplete from './store/SaleComplete'

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter, Routes, Route, Outlet, useLocation  } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


function App() {

  let homeDir = '';
  const [theme, setTheme] = useState({
    primary: "#141417ff",
    secondary: "#4a4f59ff",
    tertiary: "#e8f3ff",
    quarnary: "#259ef9",
    socialStyle: "noBorder"
  });


  const [messages, setMessages] = useState([]);
  const addToastMessage = (message) =>{
    let d = new Date();
    setMessages(prevState => [...prevState, {...message, id:d.getTime()}])
  }

  let qr_text = <>
                  <p>Free QR Code generator for any data you want to turn into a QR!</p>
                  <ul style={{width:'fit-content', margin:'0 auto', textAlign:'left'}}>
                    <li>Add your link or other data</li>
                    <li>Select your color, background, and QR styles</li>
                    <li>Select your code's efficiency (Higher Efficiency results in a smaller code, but Lower Efficiencies are easier for devices to scan) </li>
                    <li>Download your custom QR code to post, print, and display</li>
                  </ul>
                  <p>QR Generator will always be free, no need to sign up. Looking for dynamic QR codes that you can change on the fly and track? Sign up for our <a href="/waitlist">waitlist</a> to get access to our link shortener + dynamic QR solution.</p>
                </>

  return (
    <div className="App" style={{'--primary':theme['primary'],'--secondary':theme['secondary'],'--tertiary':theme['tertiary'],'--quarnary':theme['quarnary']}}>
      <BrowserRouter basename={''}>
        <Routes>
          <Route element={<Outlet context={addToastMessage}/>}>

            <Route element={<Template showFooter={false}/>}>
              <Route index element={<Hero2/>} />
            </Route>

            <Route element={<Template />}>
              <Route path={`${homeDir}/pricing`} element={<Pricing/>} />
              <Route path={`${homeDir}/qr-maker`} element={<><Helmet><title>QR Code Generator by Epicenter - Create for Free</title><meta name="description" content="The fastest QR code generator out there - for FREE" /></Helmet><h1>Epicenter's Free QR Code Maker</h1><QRGenerator/>{qr_text}</>} />
              <Route path={`${homeDir}/affiliate-directory`} element={<Directory/>} />
              <Route path={`${homeDir}/privacy-policy`} element={<TextContent content='privacy-policy'/>} />
              <Route path={`${homeDir}/terms-and-conditions`} element={<TextContent content='terms-and-conditions'/>} />

              <Route path={`${homeDir}/mission`} element={<TextContent content='mission'/>} />
              <Route path={`${homeDir}/info`} element={<TextContent content='info'/>} />
              <Route path={`${homeDir}/winwin`} element={<TextContent content='winwin'/>} />
              <Route path={`${homeDir}/landing`} element={<TextContent content='landing'/>} />
              <Route path={`${homeDir}/add-codes`} element={<AddCodesFree />} />

              <Route path={`${homeDir}/video-assets`} >
                <Route index element={<VideoAssets.AssetGrid />} />
                <Route path={`instagram-comment`} element={<VideoAssets.InstagramComment />} />
              </Route>

              <Route path={`${homeDir}/blog`} element={<Blog/>} />
              <Route path={`${homeDir}/blog/:slug`} element={<Blog/>} />


              <Route path={`${homeDir}/login`} element={<Login />} />
              <Route path={`${homeDir}/password_reset`} element={<PasswordReset />} />
              <Route path={`${homeDir}/waitlist`} element={<Waitlist />} />
            </Route>

            <Route path={`${homeDir}/parallax`} element={<Parallax/>} />

            <Route path={`${homeDir}/admin`} element={<ManagementHeader context={addToastMessage}/>}>
              <Route path={`analytics`} element={<Analytics />} shouldRevalidate={true}/>
              <Route path={`page_editor/:id`} element={<PageEditor />} />
              <Route path={`page_editor`} element={<ListView showTable='pages' alternateRedirect={'/admin/page_editor/'} />} />
              <Route path={`:table/:id`} element={<CRUD />} />
              <Route path={`:table`} element={<ListView />} />
            </Route>

            <Route path={`${homeDir}/frankencard`} element={<FrankenCard />} />
            <Route path={`${homeDir}/frankensocial`} element={<FrankenSocial />} />

            <Route path={`${homeDir}/sale_confirmation`} element={<SaleComplete />} />

            {window.location.hostname === 'ectr.io' && (
              <Route path={`${homeDir}/:link_id`} element={ <LinkRedirect/> } />
            )}

            <Route path={`${homeDir}/:slug`} element={ <Layout theme={theme} updateTheme={setTheme}/> } />
            <Route path={`${homeDir}/product/:product_id`} element={ <Product /> } />
          </Route>

        </Routes>
      </BrowserRouter>

      <ToastMessageDisplay messages={messages} setMessages={setMessages} />
    </div>

  );
}

export default App;
